@import '/apps/wt/webtools/sparta_build_server/tmp/9C7439D6/artifacts/components/utilities/global/sparta-style-utility/4.9.5/scss/_sparta-project.scss';
$moduleNameSpace: dynamic-name-space;
$utilityNamespace: 'spa-pagination';

[data-sparta-container] .#{$utilityNamespace} {
  margin: $spacing-16 0;

  ul {
    margin: 0;


    display: flex;
    line-height: 20px;
  }

  li {
    display: inline;
    border-right: 1px solid $color-gray-s20;
    margin-bottom: $spacing-16;

    @media #{$medium-up} {
      margin-bottom: 0;
    }

    &:first-child {
      padding-left: 0;

      button {
        padding-left: $spacing-12;
      }
    }

    &:last-child {
      border-right: 0;
      padding-right: 0;

      button {
        padding-right: $spacing-12;
      }
    }
  }

  .spa-pagination-btn > .spa-icon-loading {
    margin-bottom: -16px;
    margin-left: 1px;
    margin-top: -9px;

    &::before {
      background-image: icon(loader-72, (color: $color-royal-one));
      margin-top: -9px;
    }
  }

  &-advanced {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    width: 100%;

    .spa-dropdown {
      display: flex;
      padding: 0px;

      &.spa-input--condensed {
        margin: 0;

        label {
          color: $color-gray;
          font: inherit; 
          padding-top: 10px;
        }

                .spa-pagination-page-size,
        .spa-pagination-page-list {
          margin: 0 $spacing-10 $spacing-1 $spacing-10;
          width: auto;
          background-size: $spacing-16;
        }
      }
    }

    @media #{$medium-up} {
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    li:not(.spa-pagination-align-previous) {
      border: none !important;
    }
  }

  &--align-center {
    display: flex;
    justify-content: center;
  }

  &--align-right {
    display: flex;
    justify-content: flex-end;
  }

  &-align-frompagedata,
  &-align-page-size-list {
    min-width: 100%;

    @media #{$medium-up} {
      min-width: auto;
    }
  }

  &-align-previous {
    .#{$utilityNamespace}-btn--previous {
      padding-left: 3px; 
    }
  }

  &-align-page-size-list {
    @media #{$medium-up} {
      margin: 0 $spacing-16 0 auto;
    }
    @media #{$large-up} {
      margin-right: 22px; 
    }
  }

  &-btn {
    background: none;
    border: 0;
    color: $color-royal-one;
    font: inherit;
    line-height: inherit;
    margin: 0;
    padding: 0 $spacing-12;

    &:disabled,
    &:hover:disabled,
    &:focus:disabled {
      color: $color-gray;
      cursor: default;
      text-decoration: none;
    }

    &:hover,
    &:focus {
      border-radius: 2px;
      outline: 0;
      text-decoration: underline;
    }

    &--show-more {
      pointer-events: none;
    }
  }

  &-showing-text,
  &-align-page-size-list,
  &-align-pagecountlist {
    color: $color-gray;
  }
}


.progressive-pagination {
  .spa-card {
    border: 2px solid transparent;

        &.new-record {
      &:focus {
        border-color: $color-royal-one;
      }
    }
  }
}